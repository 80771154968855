import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";
import { useAuth } from "./auth/contexts/AuthProvider";
import usePageTitle from "./users/hooks/usePageTitle";
import MaterialPlannerForm from "./dashboard/SC/AddMaterial";



// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));

const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const Faq = lazy(() => import("./admin/pages/Faq"));
const HelpCenter = lazy(() => import("./admin/pages/HelpCenter"));
const Home = lazy(() => import("./admin/pages/Home"));
const HomeGC = lazy(() => import("./dashboard/GC/HomeGC"));
const ProjectList = lazy(() => import("./admin/pages/ProjectList"));
const Project = lazy(() => import("./admin/pages/Project"));
const DocumentList = lazy(() => import("./admin/pages/DocumentList"));
const Document = lazy(() => import("./admin/pages/Document"));
const CreateProject = lazy(() => import("./admin/pages/CreateProject"));
const UpdateProject = lazy(() => import("./admin/pages/UpdateProject"));
const Schedule = lazy(() => import("./admin/pages/Schedule"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const Health = lazy(() => import("./admin/pages/Health"));
// const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileInformation = lazy(
  () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));
const UserManagement = lazy(() => import("./users/pages/UserManagement"));

// Client
const Client = lazy(() => import("./client/pages/Admin"));
const ClientDashboard = lazy(() => import("./client/pages/Dashboard"));
const ClientFaq = lazy(() => import("./client/pages/Faq"));
const ClientHelpCenter = lazy(() => import("./client/pages/HelpCenter"));
const ClientHome = lazy(() => import("./client/pages/Home"));
const ClientProfile = lazy(() => import("./client/pages/Profile"));
// const ClientProfileActivity = lazy(
//   () => import("./client/pages/ProfileActivity")
// );
const ClientProfileInformation = lazy(
  () => import("./client/pages/ProfileInformation")
);
const ClientProfilePassword = lazy(
  () => import("./client/pages/ProfilePassword")
);

// Auth
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
  () => import("./auth/pages/ForgotPasswordSubmit")
);
const Login = lazy(() => import("./auth/pages/Login"));
const Register = lazy(() => import("./auth/pages/Register"));
const VerifyEmail = lazy(() => import("./auth/pages/VerifyEmail"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
  () => import("./core/pages/UnderConstructions")
);

const LandingPage = lazy(() => import("./home/Index"));
type RouteTitles = {
  [key: string]: string;
};

const routeTitles: RouteTitles = {
  "/": "BLDX - Plan Smart. Build Fast.",
  "/health": "Health Check",
  "/admin": "Admin Home",
  "/general-contractor-dashboard" : "GC",
  "/admin/dashboard": "Dashboard",
  "/admin/faq": "FAQs",
  "/admin/help": "Help Center",
  "/admin/schedule": "Schedule",
  "/admin/projects": "Project List",
  "/admin/projects/create": "Create Project",
  "/admin/projects/update/:id": "Update Project",
  "/admin/user-management": "User Management",
  "/admin/documents": "Document List",
  "/client": "Client Home",
  "/client/dashboard": "Client Dashboard",
  "/client/faq": "Client FAQs",
  "/client/help": "Client Help Center",
  "/forgot-password": "Forgot Password",
  "/reset-password/:id": "Reset Password",
  "/login": "Login",
  "/register": "Register",
  "/verify-email/:token": "Verify Email",
  "/under-construction": "Under Construction",
  "/403": "Forbidden",
  "/404": "Not Found",
};

const AppRoutes = () => {
  const { userInfo } = useAuth();  
  usePageTitle(routeTitles);
  return (
    <Routes>
      <Route path="/health" element={<Health />} />
      <Route path="/" element={<LandingPage />} />
      <PrivateRoute path="admin" element={<Admin />}>
        <PrivateRoute path="/" element={<Home />} />
        <PrivateRoute path="dashboard" element={<Dashboard />} />
        <PrivateRoute path="faq" element={<Faq />} />
        <PrivateRoute path="help" element={<HelpCenter />} />
        <PrivateRoute path="schedule" element={<Schedule />} />
        <PrivateRoute path="projects" element={<ProjectList />} />
        <PrivateRoute path="projects/:id" element={<Project />} />
        <PrivateRoute path="projects/create" element={<CreateProject />} />
        <PrivateRoute path="projects/update/:id" element={<UpdateProject />} />
        <PrivateRoute path="user-management" element={<UserManagement />} />
        
        <PrivateRoute path="documents" element={<DocumentList />} />
        <PrivateRoute path="documents/:id" element={<Document />} />
        <PrivateRoute path="profile" element={<Profile />}>
          <PrivateRoute path="/" element={<ProfileInformation />} />
          <PrivateRoute path="password" element={<ProfilePassword />} />
        </PrivateRoute>
      </PrivateRoute>
      <PrivateRoute path="client" element={<Client />}>
        <PrivateRoute path="/" element={<ClientHome />} />
        <PrivateRoute path="dashboard" element={<ClientDashboard />} />
        <PrivateRoute path="faq" element={<ClientFaq />} />
        <PrivateRoute path="help" element={<ClientHelpCenter />} />
        <PrivateRoute path="profile" element={<ClientProfile />}>
          <PrivateRoute path="/" element={<ClientProfileInformation />} />
          <PrivateRoute path="password" element={<ClientProfilePassword />} />
        </PrivateRoute>
        <PrivateRoute
          path="projects"
          element={
            <Navigate
              to={`/${process.env.PUBLIC_URL}/under-construction`}
              replace
            />
          }
        />
      </PrivateRoute>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:id" element={<ForgotPasswordSubmit />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="/verify-email/:token" element={<VerifyEmail />} />
      <Route path="under-construction" element={<UnderConstructions />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route path="/popup" element={<MaterialPlannerForm open={false} onClose={function (): void {
        throw new Error("Function not implemented.");
      } } activity={undefined} onTriggerUpdate={function (): void {
        throw new Error("Function not implemented.");
      } } />} />
      <Route
        path="*"
        element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
