import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";

interface UserInfo {
  _id: string;
  email: string;
  role: string;
  company: string;
  position : string;
}

interface AuthContextInterface {
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo: UserInfo | null;
  isAuthenticated: boolean;
  hasRole: (roles?: string[]) => boolean;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authToken, setAuthToken] = useLocalStorage<string>("authToken", "");
  const [userInfo, setUserInfo] = useLocalStorage<UserInfo | null>(
    "userInfo",
    null
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!authToken && !!userInfo
  );
  const navigate = useNavigate();

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();

  useEffect(() => {
    if (authToken && userInfo) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken, userInfo]);

  const handleLogin = async (email: string, password: string) => {
    console.log("Login");
    return login({ email, password })
      .then((data) => {
        console.log("Login d",data.user.position);

        setAuthToken(data.token);
        setUserInfo(data.user);
        setIsAuthenticated(true);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };



  const handleLogout = async () => {
    return logout()
      .then((data) => {
        setAuthToken("");
        setUserInfo(null);
        setIsAuthenticated(false);
        navigate("/login", { replace: true });
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const hasRole = (roles?: string[]): boolean => {
    if (!roles || roles.length === 0) return true;
    return !!userInfo && roles.includes(userInfo.role);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        userInfo,
        isAuthenticated,
        hasRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
