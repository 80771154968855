import { useEffect } from "react";
import { useLocation } from "react-router-dom";
type RouteTitles = {
  [key: string]: string;
};
const usePageTitle = (titles: RouteTitles): void => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const pageTitle = titles[currentPath] || "BLDX - Plan Smart. Build Fast.";
    document.title = pageTitle;
  }, [location, titles]);
};

export default usePageTitle;
