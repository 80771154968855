// Loader.tsx
import React, { useEffect } from 'react';
import logo from './loaderImage.png';

interface LoaderProps {
  size?: number;
  isLoading?: boolean;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({
  size = 100,
  isLoading = true,
  className = '',
}) => {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div className={`loader-overlay ${className}`}>
      <div className="loader-container">
        <img 
          src={logo} 
          alt="Company Logo" 
          className="loader-image"
          style={{ width: `${size}px`, height: `${size}px` }}
        />
      </div>
    </div>
  );
};

export default Loader;